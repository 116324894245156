import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Global from '../Globals';
import EventsTabPanel from './EventsTabPanel';
import Reservations from '../models/Reservations';
import CancelIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import IconSearch from '@material-ui/icons/Search';
import ConfirmDlg from '../components/ConfirmDlg';
import { Print, PrintRounded } from '@material-ui/icons';

const styles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    searchBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        justifyContent: 'flex-end'
    },
    seats: {
        color: '#a0a0a0',
        fontSize: 12
    },
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Diálogo da Lista de Reservas
export default function ReservationsListDlg(props) {
    const classes = styles();
    const { open, setOpen } = props;
    const [selectedEvent, setSelectedEvent] = useState(Global.events.length > 0 ? Global.events[0] : undefined);
    const [reservations, setReservations] = useState([]);
    const [search, setSearch] = useState('');
    const [confirmDlg, setConfirmDlg] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [seatsTaken, setSeatsTaken] = useState(0);

    const onEventSelection = (event) => {
        setSelectedEvent(event);
    }

    // Quando um novo evento é selecionado
    useEffect(() => {
        if (selectedEvent !== undefined)
            Reservations.fetch(selectedEvent).then(reservations => {
                setReservations(reservations);
            });
    }, [selectedEvent]);

    // Quando pega as reservas
    useEffect(() => {
        let seats = 0;
        reservations.forEach(item => seats += item.seats);
        setSeatsTaken(seats);
    }, [reservations]);

    // Apagar
    const onCancel = (reservation) => {
        reservation.status = 'Cancelada';
        setSelectedReservation(reservation);
        setConfirmDlg(true);
    }

    // Imprimir
    const onPrint = (reservation) => {
        const win = window.open(Global.apiURL + '/reservation/print/' + reservation.token, '_blank');
        win.focus();
    }

    // Cancelamento confirmação
    const onCancelReservation = () => {
        setConfirmDlg(false);
        Reservations.save(selectedReservation).then(result => {
            Reservations.fetch(selectedEvent).then(reservations => {
                setReservations(reservations);
            });
        });
    }

    // Close Dialog
    const onCloseBtn = () => {
        setOpen(false);
        setTimeout(() => { window.location.reload() }, 600);
    }

    return (
        <div>
            <Dialog fullScreen fullWidth={true} maxWidth='sm' onClose={() => onCloseBtn()} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} style={{ backgroundColor: Global.institution.primary_color }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onCloseBtn()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            LISTA DE RESERVAS
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={props.onPrint}>
                            <PrintRounded/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogActions>
                    <EventsTabPanel onEventSelection={onEventSelection} />
                </DialogActions>
                <DialogContent>
                    <div className={classes.searchBar}>
                        <TextField label='Filtrar' type='search' variant="outlined" InputProps={{
                            startAdornment: <InputAdornment position="start"><IconSearch /></InputAdornment>,
                        }} onChange={(event) => {
                            setSearch(event.target.value);
                        }} />
                    </div>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width={36}>&nbsp;</TableCell>
                                    <TableCell align="center" width={36}>&nbsp;</TableCell>
                                    <TableCell>NOME</TableCell>
                                    <TableCell>EMAIL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reservations.filter((reservation) => search !== '' ? (reservation.name.toLowerCase().includes(search.toLowerCase()) || reservation.user_name.includes(search)) : true).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">
                                            <IconButton style={{ color: '#303030' }}
                                                onClick={() => onPrint(row)}
                                                className={classes.button} >
                                                <Print />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.status === 'Ativa' ?
                                                <IconButton style={{ color: '#cc0000' }}
                                                    onClick={() => onCancel(row)}
                                                    className={classes.button} >
                                                    <CancelIcon />
                                                </IconButton>
                                                :
                                                <CheckIcon style={{ color: '#00cc00' }} />
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.name !== '' ? row.name : row.user_name}
                                            <br />
                                            <span className={classes.seats}>{row.seats} {row.seats > 1 ? 'assentos' : 'assento'} {row.name !== '' ? '• ADMIN' : ''}</span>
                                        </TableCell>
                                        <TableCell>{row.email !== '' ? row.email : row.user_email}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ConfirmDlg open={confirmDlg} setOpen={setConfirmDlg} title='Confirmação de Cancelamento' text='Tem certeza que deseja cancelar essa reserva?' option1='Não' option2='Sim' onOption1={() => setConfirmDlg(false)} onOption2={onCancelReservation} />
                </DialogContent>
                {selectedEvent ?
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Typography>
                            {'Ocupação: ' + seatsTaken + ' de ' + (seatsTaken > selectedEvent.seats_available ? seatsTaken : selectedEvent.seats_available) + ' assentos'}
                        </Typography>
                    </div>
                    : null}
            </Dialog>
        </div>
    )
}