import React from 'react';
import { Avatar, Button, IconButton, Tooltip } from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';
import IconLogin from '@material-ui/icons/PersonAdd';
import IconEmail from '@material-ui/icons/Mail';
import IconExit from '@material-ui/icons/ExitToApp';
import IconRead from '@material-ui/icons/ListAlt';
import IconQRCode from '@material-ui/icons/CenterFocusWeak';
import Global from '../Globals';

const UserBar = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    width: '100%',
    height: '72px',
    borderBottom: '1px solid #f2f2f2',
    marginBottom: '16px',
})

const useStyles = makeStyles((theme) => ({
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginRight: theme.spacing(2)
    },
    title: {
        ...theme.typography.subtitle2,
    },
    subTitle: {
        ...theme.typography.body2,
        fontSize: '10px',
        color: '#808080'
    },
    avatar: {
        marginRight: '24px'
    },
    emailBtn: {
        marginLeft: '16px'
    },
    readBtn: {
        marginLeft: '16px'
    },
    loginBtn: {
        marginRight: '24px'
    }
}));

export default function UserControl(props) {
    const classes = useStyles();
    console.log(props);

    // Dados do usuário Avatar
    const userDetails = () => (
        <div className={classes.flexRow}>
            <div className={classes.flexCol}>
                <div className={classes.title}>
                    {Global.user.name}
                </div>
                <div className={classes.subTitle}>
                    {Global.user.type==='Visitante' ? Global.institution.name : Global.user.type}
                </div>
            </div>
            <Avatar className={classes.avatar} style={{ backgroundColor: Global.institution.primary_color }}>
                {Global.user.name.substr(0, 1)}
            </Avatar>
        </div>
    );

    // Botão de login
    const loginBtn = () => (
        <div className={classes.flexRow}>
            <Button onClick={() => props.onLogin()} className={classes.loginBtn} style={{ color: Global.institution.primary_color }} endIcon={<IconLogin />}>Entrar</Button>
        </div>
    );

    // Sair
    const exitBtn = () => {
        localStorage.removeItem('token');
        window.location.reload();
    }

    return (
        <UserBar>
            <div>
                { Global.user===null || Global.user.type==='Visitante' ?
                <Tooltip title={`Contactar ${Global.institution.name}`}>
                    <IconButton className={classes.emailBtn} style={{ color: Global.institution.primary_color }} >
                        <IconEmail />
                    </IconButton>
                </Tooltip> 
                : 
                (Global.user!==null && Global.user.type!=='Controlador' ? <Tooltip title="Lista de Reservas">
                    <IconButton onClick={props.onReservationsList} className={classes.readBtn} style={{ color: Global.institution.primary_color }} >
                        <IconRead />
                    </IconButton>
                </Tooltip> : <Tooltip title="Leitor QRCode">
                    <IconButton onClick={props.onQRCodeReader} className={classes.readBtn} style={{ color: Global.institution.primary_color }} >
                        <IconQRCode />
                    </IconButton>
                </Tooltip>)}
                {Global.user !== null ?
                    <Tooltip title={'Sair do sistema'}>
                        <IconButton onClick={() => exitBtn()} style={{ color: Global.institution.primary_color }} >
                            <IconExit />
                        </IconButton>
                    </Tooltip>
                    :
                    null}
            </div>
            {Global.user !== null ? userDetails() : loginBtn()}
        </UserBar>
    )
}


