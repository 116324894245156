import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Check';
import Utils from '../utils/utils';
import Globals from '../Globals';
import Reservations from '../models/Reservations';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

// Diálogo de Reserva
export default function ReservationDlg(props) {
    const { open, setOpen } = props;
    const [agreed, setAgreed] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [dependent, setDependent] = useState(false);
    const [done, setDone] = useState(false);

    // Reload a página 
    useEffect(() => {
        if (!open && done) {
            window.location.reload();
        }
    }, [open, done])

    // Botão confirmar reserva
    const onConfirmBtn = () => {
        if (agreed) {
            setConfirm(true);
            const data = {
                userID: Globals.user.id,
                seats: dependent ? 2 : 1,
                eventID: props.event.id,
                name: '',
                email: ''
            }
            Reservations.save(data).then((result) => {
                console.log(result);
                setDone(true);
            });
        }
    }

    return (
        <div>
            <Dialog fullWidth={true} maxWidth='sm' onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    {Globals.institution.terms_title}
                </DialogTitle>
                <DialogContent dividers>
                    {!done ?
                        <Typography gutterBottom style={{ marginBottom: 24 }}>
                            <b>{props.event != null ? props.event.name : ''}</b>
                            <br />
                            <small>{props.event != null ? Utils.dateFormat(props.event.date_ref) : ''}</small>
                            <br /><br />
                            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: Globals.institution.terms_text }}></div>
                        </Typography>
                        :
                        <Typography gutterBottom>
                            Enviamos um e-mail de confirmação com o QRCode para liberar seu acesso!
                        </Typography>
                    }
                </DialogContent>
                <div style={{ paddingLeft: 18, marginTop: 16, marginBottom: 16 }}>
                    {!done ? <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={dependent}
                                    onChange={(event) => setDependent(event.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Levarei um dependente, preciso de dois lugares."
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreed}
                                    onChange={(event) => setAgreed(event.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Estou ciente de todas as normas e comprometo-me a segui-las."
                        /></div> :
                        null
                    }
                </div>
                <DialogActions>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        {done ?
                            <div>
                                <Button autoFocus style={{ color: '#00cc00', borderRadius: '20px', marginBottom: '16px', paddingRight: '20px', paddingLeft: '20px' }} onClick={() => setOpen(false)} startIcon={<DoneIcon />}>
                                    Concluir
                                </Button>
                            </div>
                            :
                            (!confirm ?
                                <Button autoFocus style={{ backgroundColor: agreed ? Globals.institution.primary_color : '#c0c0c0', borderRadius: '20px', marginBottom: '16px', paddingRight: '20px', paddingLeft: '20px', color: '#ffffff' }} onClick={onConfirmBtn}>
                                    Confirmar reserva
                                </Button>
                                :
                                <CircularProgress style={{ marginBottom: 16, color: Globals.institution.primary_color, fontSize: 16 }} />
                            )}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}