import Api from "../services/Api";

export default class Reservations {

    // Busca todas as reservas para um determinado evento
    static fetch = async (event) => {
        const api = new Api();
        const result = await api.get('reservations/event/' + event.id);
        return result.response;
    }

    // Salva reserva
    static save = async (data) => {
        const api = new Api();
        let result = null;
        if (data.id > 0) {
            result = await api.put('reservations/' + data.id.toString(), data);
            await api.get('reservation/mail/' + data.token);
        } else {
            result = await api.post('reservations', data);
            console.log(result);
            const id = result.response.insertId;
            let result2 = await api.get('reservations/' + id.toString(), null);
            console.log(result2);
            if (result2.response.length > 0) {
                result2= await api.get('reservation/mail/' + result2.response[0].token);
            }
        }
        return result; 
    }

}

