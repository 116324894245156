import Api from "../services/Api";

export default class Events {
    // Salva evento
    static save = async (data) => {
        const api = new Api();
        let result = null;
        if (data.id > 0) {
            result = await api.put('events/' + data.id.toString(), data);
        } else {
            result = await api.post('events', data);
        }
        return result; 
    }
}

