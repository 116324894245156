import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, CardMedia, Typography } from '@material-ui/core';
import Utils from '../utils/utils';
import Global from '../Globals';

const useStyles = makeStyles({
    card: {
        width: '100%',
        height: 'calc(100% - 16px)',
        margin: '8px',
        borderRadius: '16px',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
    },
    media: {
        height: '320px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    qrcode: {
        height: '90%',
        borderRadius: 20
    },
    details: {
        color: 'grey',
        fontSize: '14px',
    },
    instructions: {
        color: 'grey',
        fontSize: '12px',
        textAlign: 'center'
    },
    reservado: {
        color: '#00cc00',
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '8px'
    },
    detailsRow: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: '15px'
    }
});

export default function QRCodeCard() {
    const classes = useStyles();
    const event = Global.events.find((event) => Global.reservations[0].event_id === event.id);

    return (
        <Card className={classes.card}>
            <CardHeader
                title={
                    <Typography className={classes.title}>
                        {event!== undefined ? event.name : ''}
                    </Typography>
                }
                subheader={
                    <div>
                        <Typography className={classes.subtitle}>
                            {event!== undefined ? Utils.dateFormat(event.date_ref) : ''}
                        </Typography>
                        <Typography className={classes.details}>
                            {event!== undefined ? event.place : ''}
                        </Typography>
                    </div>
                }
            />
            <CardMedia
                className={classes.media}
                style={{ backgroundColor: Global.institution.primary_color + '40' }}
            >
                <img className={classes.qrcode} src={Global.apiURL + '/reservation/qr/' + Global.reservations[0].token} alt='' />
            </CardMedia>
            <CardContent>
                <div className={classes.detailsRow}>
                    <Typography className={classes.reservado}>
                        RESERVADO
                    </Typography>
                    <Typography className={classes.instructions}>
                        Mostre este QRCode na entrada do evento. Por favor procure chegar um pouco mais cedo para evitar aglomerações.
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}
