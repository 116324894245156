import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Animation from '@material-ui/core/Zoom';
import EventCard from './EventCard';
import Global from '../Globals';
import { CalendarToday } from '@material-ui/icons';

const useStyles = makeStyles({
    bigRow: {
        height: "380px"
    },
    row: {
        height: "190px"
    },
    grid: {
        marginBottom: "80px"
    }
});

export default function EventsGrid(props) {
    const classes = useStyles();
    const events = Global.events;
    const animate = true;
    let delay = 0;

    // Delay nas animações
    function getDelay() {
        delay = delay + 100;
        return delay + 'ms';
    }

    // Create the grid layout
    function gridDesktopContent() {
        let items = [];
        const activeEvents = events.filter(event => event.active === 1).length;
        for (let t = 0; t < events.length; t++) {
            if (t === 0 && activeEvents > 0) {
                items.push(
                    <Animation key={t} in={animate} style={{ transitionDelay: getDelay() }}>
                        <Grid xs={12} md={12} lg={6} className={classes.bigRow} item>
                            <EventCard event={events[t]} type="large" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} />
                        </Grid>
                    </Animation>
                );
            } else if (activeEvents === 1 && t <= 4) {
                items.push(
                    <Grid key={t} md={12} lg={6} className={classes.bigRow} container item>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={6} item>
                                <EventCard event={events[t]} type="small" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} />
                            </Grid>
                        </Animation>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={6} item>
                                {t++ < events.length - 1 ? <EventCard event={events[t]} type="small" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} /> : <div />}
                            </Grid>
                        </Animation>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={6} item>
                                {t++ < events.length - 1 ? <EventCard event={events[t]} type="small" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} /> : <div />}
                            </Grid>
                        </Animation>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={6} item>
                                {t++ < events.length - 1 ? <EventCard event={events[t]} type="small" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} /> : <div />}
                            </Grid>
                        </Animation>
                    </Grid >
                );
            } else if (activeEvents === 2 && t <= 3) {
                console.log('Eventos ativos');
                items.push(
                    <Grid key={t} md={12} lg={6} className={classes.bigRow} container item>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={12} item>
                                <EventCard event={events[t]} type="wide" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} />
                            </Grid>
                        </Animation>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={6} item>
                                {t++ < events.length - 1 ? <EventCard event={events[t]} type="small" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} /> : <div />}
                            </Grid>
                        </Animation>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={6} item>
                                {t++ < events.length - 1 ? <EventCard event={events[t]} type="small" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} /> : <div />}
                            </Grid>
                        </Animation>
                    </Grid>
                );
            } else if (activeEvents >= 3 && t <= 2) {
                items.push(
                    <Grid key={t} md={12} lg={6} className={classes.bigRow} container item>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={12} item>
                                <EventCard event={events[t]} type="wide" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} />
                            </Grid>
                        </Animation>
                        <Animation in={animate} style={{ transitionDelay: getDelay() }}>
                            <Grid xs={12} item>
                                {t++ < events.length - 1 ? <EventCard event={events[t]} type="wide" onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} /> : <div />}
                            </Grid>
                        </Animation>
                    </Grid>
                );
            } else {
                items.push(
                    <Animation key={t} in={animate} style={{ transitionDelay: getDelay() }}>
                        <Grid md={events[t].active === 0 ? 6 : 12} lg={events[t].active === 0 ? 3 : 6} item>
                            <EventCard event={events[t]} type={events[t].active === 0 ? "small" : "wide"} onLogin={props.onLogin} onReservation={props.onReservation} onCancel={props.onCancel} editing={props.editing} onEditing={props.onEditing} />
                        </Grid>
                    </Animation>
                );
            }
        }

        return items;
    }

    return (
        <>
            {
                events.length > 0 ?
                    <Grid container className={classes.grid}>
                        {gridDesktopContent()}
                    </Grid>
                    :
                    <div style={{width: '100%', textAlign: 'center', marginTop: 128}}>
                        <CalendarToday style={{ color: "#a0a0a0" }}/>
                        <Typography style={{ color: "#a0a0a0", marginTop: 12 }} >
                            NENHUM EVENTO DISPONÍVEL
                        </Typography>
                    </div>
            }
        </>
    )

}
