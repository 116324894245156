
// Variáveis globais
const Global = {
    apiURL: 'https://api.reservas.dzign-e.app',
    institutionToken: window.location.search.substr(1),
    userToken: localStorage.getItem('token'),
    user: null,
    institution: {
        name: "",
        primary_color: '#d0d0d0',
        secondary_color: '#000000'
    },
    events: [],
    reservations: []
}

export default Global;