import { useState, useEffect } from 'react';
import Api from '../services/Api';
import Global from '../Globals';

function useEvents() {
    const [events, setEvents] = useState(Global.events);
    useEffect(() => {
        const api = new Api();
        api.get('events').then(result => {
            if (result.response.length > 0) {
                setEvents(result.response);
            }
        });
    }, []);
    return events;
}

export default useEvents;