import React from 'react'
import { Typography, Fade } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import EventsGrid from './EventsGrid'
import Global from '../Globals'

const Area = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: "100%",
    padding: '8px'
})

const Title = styled('div')({
    padding: '8px',
    height: '56px'
})

const Text = styled('div')({
    padding: '8px',
    display: 'flex',
    marginBottom: '10px'
})

export default function MainArea(props) {
    return (
        <Area>
            <Title style={{ color: Global.institution.primary_color }}>
                <Typography variant="subtitle2" >
                    EVENTOS
                </Typography>
                <Fade in={Global.institution.name!==''}>
                    <Typography variant="h5" >
                        {Global.institution.name}
                    </Typography>
                </Fade>
            </Title>
            <Text>
                <Typography style={{ color: "#404040" }} >
                    Seja bem-vindo à nossa área de Reserva de Eventos! Todos os eventos disponíveis estão listados abaixo e serão liberados para reservas de acordo com a quantidade de assentos disponíveis. Você e um dependente poderão se cadastrar em apenas um evento até que ele esteja concluído. Só depois poderá cadastrar-se novamente.
                </Typography>
            </Text>
            <EventsGrid onLogin={ props.onLogin } onReservation={ props.onReservation } onCancel={ props.onCancel } editing={ props.editing } onEditing={props.onEditing} />
        </Area>
    )
}
