import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Global from '../Globals';
import Utils from '../utils/utils';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    div: {
        borderBottom: 'solid 1px #dddddd',
        width: '100%',
        borderRadius: '5'
    },
    icon: {
        fontSize: 40,
        fontWeight: 'bold'
    },
    month: {
        fontSize: 14,
        fontWeight: 'normal',
        position: 'relative',
        left: "+8px",
        top: '-14px'
    }
}));

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            width: '100%',
            backgroundColor: '#000000',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#dddddd',
        '&:hover': {
            color: '#808080',
            opacity: 1,
        },
        '&$selected': {
            color: '#000000',
            opacity: 1
        },
        '&:focus': {
            color: '#000000',
            opacity: 1
        },
    },
}))((props) => <Tab {...props} />);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#E33E7F'
        }
    }
});

export default function EventsTabPanel(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.onEventSelection(Global.events[newValue]);
    };

    const getIcon = (event) => {
        const months = ['', 'JAN', 'FEB', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
        const [day, month] = Utils.dateFormat(event.date_ref).substr(0, 5).split('/');
        const monthStr = months[parseInt(month)];

        return (
            <div className={classes.icon}>{day}<span className={classes.month}>{monthStr}</span></div>
        )
    }

    return (
        <div className={classes.root}>
            <div position="static" className={classes.div}>
                <MuiThemeProvider theme={theme}>
                    <StyledTabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                    >
                        {Global.events.map(item => <StyledTab
                            key={item.id}
                            label={item.name}
                            icon={getIcon(item)}
                            {...a11yProps(item.id)} />
                        )}
                    </StyledTabs>
                </MuiThemeProvider>
            </div>
        </div>
    );
}