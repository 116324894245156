import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Icon0 from '@material-ui/icons/CenterFocusWeak';
import Icon1 from '@material-ui/icons/CheckCircle';
import Icon2 from '@material-ui/icons/Warning';
import Icon3 from '@material-ui/icons/Error';
import Icon4 from '@material-ui/icons/Block';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Global from '../Globals';
import Reservations from '../models/Reservations';
import EventsTabPanel from './EventsTabPanel';
import DialogActions from '@material-ui/core/DialogActions';

const styles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    msgDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
    },
    msgTitle: {
        fontSize: 54,
        fontWeight: 'bold',
        paddingTop: 54,
        paddingBottom: 24
    },
    msgSubTitle: {
        fontSize: 32,
        paddingBottom: 12
    }
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const messages = [
    { 'id': 0, 'icon': <Icon0 style={{color: '#808080', width: 120, height: 120}}/>, 'color': '#808080', 'title': 'AGUARDANDO IDENTIFICAÇÃO' },
    { 'id': 1, 'icon': <Icon1 style={{color: '#00cc00', width: 120, height: 120}}/>, 'color': '#00cc00', 'title': 'ENTRADA PERMITIDA' },
    { 'id': 2, 'icon': <Icon2 style={{color: '#0000cc', width: 120, height: 120}}/>, 'color': '#0000cc', 'title': 'ENTRADA AUTORIZADA ANTERIORMENTE' },
    { 'id': 3, 'icon': <Icon3 style={{color: '#cc0000', width: 120, height: 120}}/>, 'color': '#cc0000', 'title': 'CÓDIGO INVÁLIDO' },
    { 'id': 4, 'icon': <Icon4 style={{color: '#808080', width: 120, height: 120}}/>, 'color': '#808080', 'title': 'LEITURA INATIVA' }
];

// Diálogo do Leitor de QRCode
export default function QRCodeReaderDlg(props) {
    const classes = styles();
    const { open, setOpen } = props;
    //const selectedEvent = Global.events.length > 0 ? Global.events[0] : undefined;
    const [selectedEvent, setSelectedEvent] = useState(Global.events.length > 0 ? Global.events[0] : undefined);
    const [qrCode, setQRCode] = useState('');
    const [reservations, setReservations] = useState([]);
    const [reservation, setReservation] = useState(undefined);
    const [message, setMessage] = useState(messages[0]);
    const [reading, setReadinng] = useState(true);

    // Close Dialog
    const onCloseBtn = () => {
        setOpen(false);
        setTimeout(() => { window.location.reload() }, 600);
    }

    useEffect(() => {
        if (selectedEvent !== undefined)
            Reservations.fetch(selectedEvent).then(reservations => {
                setReservations(reservations);
            });
    }, [selectedEvent]);

    useEffect(() => {
        if (Global.events.length > 0) { 
            setSelectedEvent(Global.events[0]);
        }
    // eslint-disable-next-line
    }, [Global.events.length]);

    useEffect(() => {
        if (qrCode.length === 32) {
            const _reservation = reservations.find((item) => item.token === qrCode);
            setReservation(_reservation)
            if (_reservation !== undefined) {
                if (_reservation.status === 'Ativa') {
                    const data = {
                        id: _reservation.id,
                        status: 'Concluída',
                        token: _reservation.token
                    }
                    Reservations.save(data).then(result => {
                        Reservations.fetch(selectedEvent).then(reservations => {
                            setReservations(reservations);
                            setMessage(messages[1]);
                        });
                    });
                } else {
                    setMessage(messages[2]);
                }
            } else {
                setMessage(messages[3]);
            }
            setQRCode('');
            setTimeout(() => setMessage(messages[0]), 5000);
        }
    }, [qrCode, reservations, selectedEvent]);

    const onEventSelection = (event) => {
        setSelectedEvent(event);
    }

    // QRCode change
    const onQRCodeTextField = (event) => {
        setQRCode(event.target.value);
    }

    // Get Message
    const getMessage = () => {
        if (!reading) {
            return (
                <div className={classes.msgDiv}>
                    {messages[4].icon}
                    <div className={classes.msgTitle} style={{ color: message.color }}>
                        {messages[4].title}
                    </div>
                    <div className={classes.msgSubTitle} style={{ color: '#a0a0a0' }}>
                        Clique no campo abaixo para ativar a leitura.
                </div>
                </div>
            )
        } else if (message.id === 0) {
            return (
                <div className={classes.msgDiv}>
                    {message.icon}
                    <div className={classes.msgTitle} style={{ color: message.color }}>
                        {message.title}
                    </div>
                    <div className={classes.msgSubTitle} style={{ color: '#a0a0a0' }}>
                        Aproxime o QRCode da leitora para liberar sua entrada.
                    </div>
                </div>
            )
        } else if (message.id === 1) {
            return (
                <div className={classes.msgDiv}>
                    {message.icon}
                    <div className={classes.msgTitle} style={{ color: message.color }}>
                        {message.title}
                    </div>
                    <div className={classes.msgTitle} style={{ color: '#a0a0a0'}}>
                        {reservation.name !== '' ? reservation.name : reservation.user_name}
                    </div>
                    <div className={classes.msgSubTitle} style={{ color: '#a0a0a0' }}>
                        ( {reservation.seats} {reservation.seats === 1 ? 'ASSENTO' : 'ASSENTOS'} )
                    </div>
                </div>
            )
        } else if (message.id === 2) {
            return (
                <div className={classes.msgDiv}>
                    {message.icon}
                    <div className={classes.msgTitle} style={{ color: message.color }}>
                        {message.title}
                    </div>
                    <div className={classes.msgSubTitle} style={{ color: '#a0a0a0' }}>
                        Solicite ajuda no local.
                    </div>
                </div>
            )
        } else if (message.id === 3) {
            return (
                <div className={classes.msgDiv}>
                    {message.icon}
                    <div className={classes.msgTitle} style={{ color: message.color }}>
                        {message.title}
                    </div>
                    <div className={classes.msgSubTitle} style={{ color: '#a0a0a0' }}>
                        Solicite ajuda no local.
                    </div>
                </div>
            )
        }

    }

    return (
        <div>
            <Dialog fullScreen fullWidth={true} maxWidth='sm' onClose={() => onCloseBtn()} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} style={{ backgroundColor: Global.institution.primary_color }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onCloseBtn()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            CONTROLE DE PRESENÇAS
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogActions>
                    <EventsTabPanel onEventSelection={onEventSelection} />
                </DialogActions>
                <DialogContent className={classes.root}>
                    <div>
                        <Typography variant="h1" component="h2" style={{marginTop:52}}>
                            {getMessage()}
                        </Typography>
                    </div>
                    <TextField inputRef={input => input && input.focus()} placeholder='ATIVAR LEITURA' value={qrCode} autoFocus variant="outlined" onFocus={() => setReadinng(true)} onBlur={() => setReadinng(false)} onChange={event => onQRCodeTextField(event)} style={{ marginTop: 52, opacity: reading ? 0 : 1 }} inputProps={{min: 0, style: { textAlign: 'center' }}} />
                </DialogContent>
            </Dialog>
        </div>
    )
}