import React, { useState, useEffect } from 'react';
import CryptoJS from "crypto-js";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { TextField, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import VerifiedIcon from '@material-ui/icons/Check';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import User from '../models/User';
import Global from '../Globals';

const styles = (theme) => ({
    button: {
        marginBottom: '12px',
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

// Diálogo de Login
export default function LoginDlg(props) {
    const classes = styles();
    const [doLogin, setDoLogin] = useState(false);
    const { open, setOpen } = props;
    const [id, setID] = useState(0); 
    const [name, setName] = useState(''); 
    const [email, setEmail] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [phone, setPhone] = useState('');
    const [riskGroup, setRiskGroup] = useState(false);
    const [accessibility, setAccessibility] = useState(false);
    const [message, setMessage] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [verified, setVerified] = useState(false);

    // Verifica o email
    const checkEmail = () => {
        if (email.trim() !== '') {
            setMessage('');
            User.getByEmail(email.trim()).then((result) => {
                if (result.response.length > 0) {
                    let user = result.response[0];
                    setID(user.id);
                    setName(user.name);
                    setEmail(user.email);
                    setDateOfBirth(user.date_of_birth);
                    setPhone(user.celular);
                    setRiskGroup(user.risk_group === 1);
                    setAccessibility(user.accessibility === 1);
                    setDoLogin(true);
                } else {
                    setShowDetails(true);
                }
            });
        } else {
            setMessage('Por favor preencha o campo de email!');
        }
    }

    // Efetua o login
    const login = () => {
        console.log('Logging...');
        if (name.trim() !== '' && email.trim() !== '' && phone.trim() !== '') {
            const userData = {
                "id": id,
                "photo": "",
                "name": name.trim(),
                "email": email.trim().toLowerCase(),
                "phone": phone.trim(),
                "date_of_birth": birthToMySQL(dateOfBirth),
                "accessibility": accessibility ? 1 : 0,
                "risk_group": riskGroup ? 1 : 0
            };
            User.login(userData).then((result) => {
                console.log(result);
                setShowOtp(true);
                checkOTP();
            });
        } else if (showDetails) {
            setMessage('Por favor preencha todos os campos.');
        }
    }

    // Birth to mysql
    const birthToMySQL = (birth) => {
        console.log(birth);
        birth = new Date(birth);
        const date = (new Date(birth - (new Date()).getTimezoneOffset() * 60000)).toISOString();
        console.log(date);
        return date.substr(0, 10); 
    }

    // Caso email já esteja cadastrado efetua o login
    useEffect(login, [doLogin]);

    // Reload a página no caso do usuário ser verificado
    useEffect(() => {
        if (!open && verified) {
            window.location.reload();
        } else if (!open) {
            setDateOfBirth(null);
        }
    }, [open, verified]);

    // Checa a otp
    const checkOTP = () => {
        console.log('Verifying email...');
        User.getByEmail(email).then((result) => {
            console.log(result);
            if (result.response.length > 0) {
                const user = result.response[0];
                if (user.active === 0) {
                    setTimeout(checkOTP, 5000);
                } else {
                    localStorage.setItem('token', CryptoJS.MD5(email).toString());
                    Global.userToken = CryptoJS.MD5(email).toString();
                    setVerified(true);
                    console.log('Email verified!');
                }
            } else {
                // TODO ERROR
            }
        });
    }

    // Campo de email e outros
    const fields = () => (
        showDetails ? <div>
            <TextField
                id="txtEmail"
                size="small"
                label="Email"
                fullWidth
                disabled
                margin="normal"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            {details()}
        </div> : <TextField
                id="txtEmail"
                size="small"
                label="Email"
                fullWidth
                margin="normal"
                variant="outlined"
                value={email}
                style={{ marginBottom: 18 }}
                onChange={(event) => setEmail(event.target.value)}
            />
    )

    // Botão cancelar
    const onCancel = () => {
        setName('');
        setPhone('');
        setDateOfBirth(null);
        setAccessibility(false);
        setRiskGroup(false);
        setShowOtp(false);
        setShowDetails(false);
        setID(0);
        setEmail('');
    }

    // Botão ok
    const onBtnOk = () => {
        setOpen(false);
    }

    // Campos com detalhes do usuário
    const details = () => (<div>
        <TextField
            id="txtName"
            size="small"
            label="Nome completo"
            fullWidth
            margin="normal"
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <KeyboardDatePicker
                margin="normal"
                id="txtEventDate"
                label="Data de nascimento"
                value={dateOfBirth}
                onChange={(date) => setDateOfBirth(date)}
                disablePast={false}
                disableFuture = {true}
                format='dd/MM/yyyy'
                invalidDateMessage=''
                KeyboardButtonProps={{
                    'aria-label': 'mudar data',
                }}
            />
        </MuiPickersUtilsProvider>
        <TextField
            id="txtPhone"
            size="small"
            label="Celular"
            fullWidth
            margin="normal"
            variant="outlined"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
        />
        <FormControlLabel
            control={
                <Checkbox
                    checked={accessibility}
                    onChange={(event) => setAccessibility(event.target.checked)}
                    color="primary"
                />
            }
            label="Possuo dificuldade de locomoção"
        />
        <FormControlLabel
            control={
                <Checkbox
                    checked={riskGroup}
                    onChange={(event) => setRiskGroup(event.target.checked)}
                    color="primary"
                />
            }
            label="Pertenço ao grupo de maior risco"
        />
    </div>)

    // Aguardando confirmação do email
    const otpField = () => (
        <div style={{ textAlign: 'center' }}>
            <CircularProgress style={{ marginTop: 24, marginBottom: 48 }} />
            <Typography gutterBottom style={{ marginBottom: 24 }}>
                Por favor mantenha essa janela aberta e siga as orientações que foram enviadas para <b><i>{email}</i></b> a fim de confirmar seu cadastro. Verifique também na caixa de SPAM. Caso não tenha recebido, tente novamente.
            </Typography>
            <Button 
                className={classes.button} 
                variant="contained" 
                color='primary'
                onClick={() => checkOTP()}
            >
                Já fiz a confirmação no e-mail
            </Button>
        </div>
    )

    // Email verificado
    const verifiedFields = () => (
        <div style={{ textAlign: 'center' }}>
            <VerifiedIcon style={{ fontSize: 90, color: "#00a000" }} />
            <Typography gutterBottom style={{ marginBottom: 24, marginTop: 48 }}>
                <b>Seja bem-vindo!</b><br />Agora você pode fazer suas reservas.
            </Typography>
        </div>
    )

    return (
        <div>
            <Dialog fullWidth={true} maxWidth='sm' onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    {!verified ? (showOtp ? "Aguardando confirmação" : "Dados Pessoais") : "Cadastro Confirmado!"}
                </DialogTitle>
                <DialogContent dividers>
                    {verified ?
                        verifiedFields()
                        :
                        (!showOtp ?
                            fields()
                            :
                            otpField())
                    }
                    {message !== '' ? <Alert severity="error">{message}</Alert> : null}
                </DialogContent>
                <DialogActions>
                    {!showOtp ? (showDetails ?
                        <Button autoFocus onClick={() => login()}>
                            Próximo
                        </Button>
                        :
                        <Button autoFocus onClick={() => checkEmail()}>
                            Próximo
                        </Button>)
                        : (
                            !verified ?
                                <Button autoFocus onClick={() => onCancel()}>
                                    Tentar novamente
                                </Button>
                                :
                                <Button autoFocus onClick={() => onBtnOk()}>
                                    Concluir
                                </Button>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}