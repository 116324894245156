import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core/'
import CalendarControl from '../components/CalendarControl'
import UserControl from '../components/UserControl'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        flexShrink: 0
    },
    drawerPaper: {
        width: '380px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center'
    },

}));

export default function DesktopBar(props) {
    const classes = useStyles();
    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="right"
        >
            <UserControl onLogin={ props.onLogin } onReservationsList={props.onReservationsList} onQRCodeReader={props.onQRCodeReader}/>
            <CalendarControl />
        </Drawer>
    )
}
