import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/ListAlt';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Global from '../Globals';
import IconSearch from '@material-ui/icons/Search';
import ConfirmDlg from '../components/ConfirmDlg';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import IconPresence from '@material-ui/icons/CenterFocusStrong';
import IconConcluded from '@material-ui/icons/Check';
import Reservations from '../models/Reservations';
import EventsTabPanel from './EventsTabPanel';

const Area = styled('div')({
    marginTop: 80,
    width: "100%",
    padding: '8px'
})

const styles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    searchBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        justifyContent: 'flex-end'
    },
    seats: {
        color: '#a0a0a0',
        fontSize: 12
    }
}));

export default function PresenceList(props) {
    const classes = styles();
    //const selectedEvent = Global.events.length > 0 ? Global.events[0] : undefined;
    const [selectedEvent, setSelectedEvent] = useState(Global.events.length > 0 ? Global.events[0] : undefined);
    const [search, setSearch] = useState('');
    const [reservations, setReservations] = useState([]);
    const [confirmDlg, setConfirmDlg] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);

    useEffect(() => {
        if (selectedEvent !== undefined)
            Reservations.fetch(selectedEvent).then(reservations => {
                setReservations(reservations);
            });
    }, [selectedEvent]);

    useEffect(() => {
        if (Global.events.length > 0) { 
            setSelectedEvent(Global.events[0]);
        }
    // eslint-disable-next-line
    }, [Global.events.length]);

    const onPresenceBtn = (reservation) => {
        setSelectedReservation(reservation);
        setConfirmDlg(true);
    }

    const onEventSelection = (event) => {
        setSelectedEvent(event);
    }

    // Presença confirmação
    const onPresenceSave = () => {
        setConfirmDlg(false);
        const data = {
            id: selectedReservation.id,
            status: 'Concluída',
            token: selectedReservation.token
        }
        Reservations.save(data).then(result => {
            Reservations.fetch(selectedEvent).then(reservations => {
                setReservations(reservations);
            });
        });
    }

    return (
        <div>
            <AppBar style={{ backgroundColor: Global.institution.primary_color }}>
                <Toolbar>
                    <Icon />
                    <Typography variant="h6" className={classes.title}>
                        LISTA DE RESERVAS
                    </Typography>
                </Toolbar>
            </AppBar>
            <Area>
                <EventsTabPanel onEventSelection={onEventSelection} /><br/>
                <div className={classes.searchBar}>
                    <TextField label='Filtrar' type='search' variant="outlined" InputProps={{
                        startAdornment: <InputAdornment position="start"><IconSearch /></InputAdornment>,
                    }} onChange={(event) => {
                        setSearch(event.target.value);
                    }} />
                </div>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" width={36}>&nbsp;</TableCell>
                                <TableCell>NOME</TableCell>
                                <TableCell>EMAIL</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reservations.filter((reservation) => search !== '' ? (reservation.name.toLowerCase().includes(search.toLowerCase()) || reservation.user_name.toLowerCase().includes(search.toLowerCase())) : true).map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">
                                        {row.status === 'Ativa' ?
                                            <IconButton style={{ color: '#0000cc' }}
                                                onClick={() => onPresenceBtn(row)}
                                                className={classes.button} >
                                                <IconPresence />
                                            </IconButton>
                                            :
                                            <IconConcluded style={{ color: '#00cc00' }} />
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.name !== '' ? row.name : row.user_name}
                                        <br />
                                        <span className={classes.seats}>{row.seats} {row.seats > 1 ? 'assentos' : 'assento'} {row.name !== '' ? '• ADMIN' : ''}</span>
                                    </TableCell>
                                    <TableCell>{row.email !== '' ? row.email : row.user_email}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Area>
            <ConfirmDlg key={selectedReservation !== null ? selectedReservation.id : 0} open={confirmDlg} setOpen={setConfirmDlg} title='Confirmação de Presença'
                text={selectedReservation !== null ? 'Confirmar a entrada de ' + (selectedReservation.name !== '' ? selectedReservation.name : selectedReservation.user_name) + ' no ' + (selectedEvent !== undefined ?
                    (selectedEvent.name)
                    :
                    '') + "?" : ''}
                option1='Não' option2='Sim' onOption1={() => setConfirmDlg(false)} onOption2={onPresenceSave} />
        </div>
    )
}
