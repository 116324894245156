import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, CardMedia, Button, Typography, IconButton } from '@material-ui/core'
import ReserveIcon from '@material-ui/icons/PersonAddRounded'
import EventIcon from '@material-ui/icons/Event';
import CancelIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Global from '../Globals';
import Utils from '../utils/utils';
import Reservado from '../assets/images/reservado1.jpg';

const useStyles = makeStyles((theme) => ({
    card: {
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
        margin: '8px',
        borderRadius: '16px',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
    },
    cardWide: {
        width: 'calc(100% - 16px)',
        height: 'calc(190px - 16px)',
        margin: '8px',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
    },
    cardSmall: {
        width: 'calc(100% - 16px)',
        height: 'calc(190px - 16px)',
        margin: '8px',
        borderRadius: '16px',
        opacity: '0.7',
        boxShadow: 'none'
    },
    media: {
        height: '196px',
        textAlign: 'right'
        //paddingTop: '56.25%', // 16:9
    },
    details: {
        color: 'grey',
        fontSize: '14px',
    },
    detailsRow: {
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        borderRadius: '20px',
        marginTop: '10px',
        marginRight: '10px',
        color: '#ffffff'
    },
    detailsWide: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%'
    },
    cover: {
        width: '50%',
        height: '100%',
        textAlign: 'right'
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: '15px'
    },
    imageDay: {
        fontFamily: 'Arial',
        letterSpacing: '-10px',
        fontSize: '220px',
        fontWeight: 'bold',
        color: '#ffffff70',
        position: 'relative',
        left: '-20px',
        top: '-35px'
    },
    reserved: {
        width: '100%',
        textAlign: 'left',
        height: '196px',
        position: 'relative',
        left: '0px',
        top: '-330px'
    },
    reservedWide: {
        width: '100%',
        textAlign: 'left',
        height: '100%',
        position: 'relative',
        left: '0px',
        top: '-150px'
    },
    imageDayWide: {
        fontFamily: 'Arial',
        letterSpacing: '-5px',
        fontSize: '100px',
        fontWeight: 'bold',
        color: '#ffffff70',
        position: 'relative',
        left: '-10px',
        top: '70px'
    }
}));

export default function EventCard(props) {
    const classes = useStyles();
    const cards = { 'large': largeCard, 'wide': wideCard, 'small': smallCard };
    const hasReservation = (Global.reservations.length > 0 && Global.user !== null && Global.user.type === 'Visitante' && Global.reservations.find((item) => item.type === props.event.type));
    const reservation = hasReservation ? Global.reservations.find((item) => item.event_id === props.event.id) : undefined;
    let seatsAvailable = props.event.seats_available - (props.event.seats_taken !== null ? props.event.seats_taken : 0);
    seatsAvailable = seatsAvailable < 0 ? 0 : seatsAvailable;

    // Reservar
    const reserveBtn = () => {
        if (Global.userToken === null) {
            props.onLogin();
        } else {
            props.onReservation(props.event);
        }
    }

    // Cancel button
    const cancelBtn = () => {
        props.onCancel(reservation);
    }

    // Edit Button
    const editBtn = () => {
        props.onEditing(props.event);
    }

    // Large Card
    function largeCard() {
        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <EventIcon style={{ color: Global.institution.primary_color }} fontSize="large" />
                    }
                    title={
                        <Typography className={classes.title}>
                            {props.event.name}
                        </Typography>
                    }
                    subheader={
                        <Typography className={classes.subtitle}>
                            {Utils.dateFormat(props.event.date_ref)}
                        </Typography>
                    }
                    action={
                        props.editing ?
                            <Button style={{ backgroundColor: '#0000ff' }}
                                onClick={() => editBtn()}
                                color="primary"
                                disableElevation
                                className={classes.button}
                                variant="contained"
                                size='medium'
                                startIcon={<EditIcon />}> Editar</Button>
                            :
                            (reservation !== undefined ?
                                <Button style={{ backgroundColor: '#cc0000' }}
                                    onClick={() => cancelBtn()}
                                    color="primary"
                                    disableElevation
                                    className={classes.button}
                                    variant="contained"
                                    size='medium'
                                    startIcon={<CancelIcon />}> Cancelar</Button>
                                :
                                (
                                    !hasReservation && seatsAvailable > 0 ?
                                        <Button style={{ backgroundColor: Global.institution.primary_color }}
                                            onClick={() => reserveBtn()}
                                            color="primary"
                                            disableElevation
                                            className={classes.button}
                                            variant="contained"
                                            size='medium'
                                            startIcon={<ReserveIcon />}> Reservar</Button>
                                        :
                                        null
                                ))
                    } />
                <CardMedia
                    className={classes.media}
                    image={Global.apiURL + '/eventphoto/' + props.event.photo}
                    style={{ backgroundColor: Global.institution.primary_color + '40' }}
                >
                    <Typography className={classes.imageDay}>
                        {Utils.dateFormat(props.event.date_ref).substr(0, 2)}
                    </Typography>
                    {reservation !== undefined ?
                        <div className={classes.reserved}><img src={Reservado} alt='' style={{ height: '196px' }}></img></div> : null}
                </CardMedia>
                <CardContent>
                    <div className={classes.detailsRow}>
                        <Typography className={classes.details}>
                            {props.event.place}
                        </Typography>
                        <Typography className={classes.details}>
                        {seatsAvailable > 0 ?
                                seatsAvailable + ' lugares disponíveis'
                                :
                                <span style={{color: '#cc0000'}}>ESGOTADO</span>}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        );
    }

    // Wide Card
    function wideCard() {
        return (
            <Card className={classes.cardWide}>
                <CardMedia
                    className={classes.cover}
                    image={Global.apiURL + '/eventphoto/' + props.event.photo}
                    style={{ backgroundColor: Global.institution.primary_color + '40' }}
                >
                    <Typography className={classes.imageDayWide}>
                        {Utils.dateFormat(props.event.date_ref).substr(0, 2)}
                    </Typography>
                    {reservation !== undefined ?
                        <div className={classes.reservedWide}><img src={Reservado} alt='' style={{ height: '100%' }}></img></div> : null}
                </CardMedia>
                <div className={classes.detailsWide}>
                    <CardHeader
                        title={
                            <Typography className={classes.title}>
                                {props.event.name}
                            </Typography>
                        }
                        subheader={
                            <Typography className={classes.subtitle}>
                                {Utils.dateFormat(props.event.date_ref)}
                            </Typography>
                        }
                        action={
                            props.editing ?
                                <IconButton style={{ color: '#0000ff' }}
                                    onClick={() => editBtn()}
                                    className={classes.button} >
                                    <EditIcon />
                                </IconButton>
                                :
                                (reservation !== undefined ?
                                    <IconButton style={{ color: '#cc0000' }}
                                        onClick={() => cancelBtn()}
                                        className={classes.button} >
                                        <CancelIcon />
                                    </IconButton>
                                    :
                                    (
                                        !hasReservation && seatsAvailable > 0 ?
                                            <IconButton style={{ color: Global.institution.primary_color }}
                                                onClick={() => reserveBtn()}
                                                className={classes.button} >
                                                <ReserveIcon />
                                            </IconButton>
                                            :
                                            null
                                    ))
                        } />
                    <CardContent>
                        <div className={classes.detailsRow}>
                            <Typography className={classes.details}>
                                {props.event.place}
                            </Typography>
                            <Typography className={classes.details}>
                            {seatsAvailable > 0 ?
                                seatsAvailable + ' lugares disponíveis'
                                :
                                <span style={{color: '#cc0000'}}>ESGOTADO</span>}
                            </Typography>
                        </div>
                    </CardContent>
                </div>
            </Card>
        );
    }

    // Small Card
    function smallCard() {
        return (
            <Card className={classes.cardSmall}>
                <CardHeader
                    title={
                        <Typography className={classes.title}>
                            {props.event.name}
                        </Typography>
                    }
                    subheader={
                        <Typography className={classes.subtitle}>
                            {Utils.dateFormat(props.event.date_ref)}
                        </Typography>
                    }
                    action={
                        props.editing ?
                            <IconButton style={{ color: '#0000ff' }}
                                onClick={() => editBtn()}
                                className={classes.button} >
                                <EditIcon />
                            </IconButton>
                            : null
                    }
                />
                <CardContent>
                    <div className={classes.detailsRow}>
                        <Typography className={classes.details}>
                            {props.event.place}
                        </Typography>
                        <Typography className={classes.details}>
                            {seatsAvailable > 0 ?
                                seatsAvailable + ' lugares disponíveis'
                                :
                                <span style={{color: '#cc0000'}}>ESGOTADO</span>}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        );
    }

    // Return the selected Card
    return cards[props.type]();
}