import { useState, useEffect } from 'react';
import Api from '../services/Api';
import Global from '../Globals';

function useReservations() {
    const [reservations, setReservations] = useState(Global.reservations);
    useEffect(() => {
        const api = new Api();
        api.get('reservations').then(result => {
            if (result.response.length > 0) {
                setReservations(result.response);
            }
        });
    }, []);
    return reservations;
}

export default useReservations;