import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Checkbox, CircularProgress, TextField } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Check';
import Utils from '../utils/utils';
import Globals from '../Globals';
import Reservations from '../models/Reservations';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

// Diálogo de Reserva do Administrador
export default function ReservationAdminDlg(props) {
    const { open, setOpen } = props;
    const [confirm, setConfirm] = useState(false);
    const [dependent, setDependent] = useState(false);
    const [done, setDone] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    // Reload a página 
    useEffect(() => {
        if (!open && done) {
            window.location.reload();
        }
    }, [open, done])

    // Botão confirmar reserva
    const onConfirmBtn = () => {
        setConfirm(true);
        const data = {
            userID: Globals.user.id,
            seats: dependent ? 2 : 1,
            eventID: props.event.id,
            name: name,
            email: email
        }
        Reservations.save(data).then((result) => {
            setDone(true);
        });
    }

    return (
        <div>
            <Dialog fullWidth={true} maxWidth='sm' onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    {Globals.user === null || Globals.user.type === 'Visitante' ? Globals.institution.terms_title : 'NOVA RESERVA'}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ marginBottom: 0 }}>
                        <b>{props.event != null ? props.event.name : ''}</b>
                        <br />
                        <small>{props.event != null ? Utils.dateFormat(props.event.date_ref) : ''}</small>
                        <br /><br />
                    </Typography>
                    <TextField
                        id="txtName"
                        size="small"
                        label="Nome do visitante"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <TextField
                        id="txtEmail"
                        size="small"
                        label="Email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        style={{marginBottom: 32}}
                    />
                </DialogContent>
                <div style={{ paddingLeft: 18, marginTop: 16, marginBottom: 16 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dependent}
                                onChange={(event) => setDependent(event.target.checked)}
                                color="primary"
                            />
                        }
                        label="Incluir um assento para dependente."
                    />
                </div>
                <DialogActions>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        {done ?
                            <div>
                                <Button autoFocus style={{ color: '#00cc00', borderRadius: '20px', marginBottom: '16px', paddingRight: '20px', paddingLeft: '20px' }} onClick={() => setOpen(false)} startIcon={<DoneIcon />}>
                                    Reserva concluída
                                </Button>
                            </div>
                            :
                            (!confirm ?
                                <Button autoFocus style={{ backgroundColor: Globals.institution.primary_color, borderRadius: '20px', marginBottom: '16px', paddingRight: '20px', paddingLeft: '20px', color: '#ffffff' }} onClick={onConfirmBtn}>
                                    Confirmar reserva
                                </Button>
                                :
                                <CircularProgress style={{ marginBottom: 16, color: Globals.institution.primary_color, fontSize: 16 }} />
                            )}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}