import { useState, useEffect } from 'react';
import Api from '../services/Api';
import Global from '../Globals';

function useUser() {
    const [user, setUser] = useState(Global.user);
    useEffect(() => {
        const api = new Api();
        if (Global.userToken !== null)
            api.get('user').then(result => {
                if (result.response.length > 0) {
                    setUser(result.response[0]);
                }
            });
    }, []);
    return user;
}

export default useUser;