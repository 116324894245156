import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useAllEvents from '../hooks/AllEvents';
import { IconButton, Table, TableContainer } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { AccessTime, Check, Print } from '@material-ui/icons';
import Utils from '../utils/utils';
import Global from '../Globals';

const styles = makeStyles((theme) => ({

}));

// Diálogo de Edição de Eventos
export default function EventsPrintDlg(props) {
    const classes = styles();
    const { open, setOpen } = props;
    const events = useAllEvents();

    const onPrint = (event) => {
        const win = window.open(Global.apiURL + '/events/print/' + event.token, '_blank');
        win.focus();
    }

    return (
        <div>
            <Dialog fullWidth={true} maxWidth='sm' onClose={() => setOpen(false)} open={open}>
                <DialogTitle onClose={() => setOpen(false)}>
                    <div className={classes.datePlace}>
                        Impressão | Lista de Presença
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                {events.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">
                                            {row.seats_taken!=null ?
                                            <IconButton style={{ color: '#303030' }}
                                                onClick={() => onPrint(row)}
                                                className={classes.button} >
                                                <Print />
                                            </IconButton> : <div></div>}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Utils.dateFormat(row.date_ref)} 
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {(row.seats_taken!=null ? row.seats_taken : 0) + "/" + row.seats_available}
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {row.next ? <AccessTime style={{color: '#0000aa'}}/> : <Check style={{color: '#00aa00'}}/>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </div>
    )
}