import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/CalendarToday';
import '../assets/css/CustomCalendar.css';
import Utils from '../utils/utils';
import Global from '../Globals';

const useStyles = makeStyles({
    root: {
        width: '90%'
    },
    list: {
        width: '100%',
        maxWidth: 360
    },
    active: {
        boxShadow: 'inset 0px 0px 0px 2px #000000',
        color: '#000000'
    },
});

export default function CalendarControl() {
    const [selectedDate, setSelectedDate] = useState('');
    const classes = useStyles();

    function onChange(date) {
        setSelectedDate(date.toLocaleString('pt-BR', {
            dateStyle: "short"
        }).substr(0, 10));
    }

    function getActive(date) {
        const day = date.date.toLocaleString('pt-BR', {
            dateStyle: "short"
        }).substr(0, 10);
        return Global.events.find(item => Utils.dateFormat(item.date_ref).substr(0, 10) === day) ? classes.active : null;
    }

    return (
        <div className={classes.root}>
            <Calendar
                locale='pt-BR'
                onChange={onChange}
                tileClassName={getActive}
            />
            <div>
                <List className={classes.list}>
                    {Global.events.filter(item => Utils.dateFormat(item.date_ref).substr(0, 10) === selectedDate).map(item =>
                        (
                            <ListItem key={item.id}>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: Global.institution.primary_color }}>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.name} secondary={item.place + " - " + Utils.timeFormat(item.date_ref)} />
                            </ListItem>
                        ))}
                </List>
            </div>
        </div>
    )
}
