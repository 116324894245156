import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DelIcon from '@material-ui/icons/Edit';
import EdiIcon from '@material-ui/icons/DoneRounded';
import MainArea from './components/MainArea';
import DesktopBar from './components/DesktopBar';
import Global from './Globals';
import UserControl from './components/UserControl';
import useInstitution from './hooks/Institution';
import useUser from './hooks/User';
import LoginDlg from './components/LoginDlg';
import ReservationDlg from './components/ReservationDlg';
import ReservationsListDlg from './components/ReservationsListDlg';
import QRCodeCard from './components/QRCodeCard';
import useReservations from './hooks/Reservations';
import useEvents from './hooks/Events';
import Reservations from './models/Reservations';
import ConfirmDlg from './components/ConfirmDlg';
import EventFormDlg from './components/EventFormDlg';
import Events from './models/Events';
import ReservationAdminDlg from './components/ReservationAdminDlg';
import PresenceList from './components/PresenceList';
import QRCodeReaderDlg from './components/QRCodeReaderDlg';
import EventsPrintDlg from './components/EventsPrintDlg';
import Utils from './utils/utils';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? "calc(100% - 16px)" : "calc(100% - 396px)"
  },
  fab: {
    background: "#00cc00",
    '&:hover': {
      background: "#00aa00",
    },
    color: "#ffffff",
    position: 'fixed',
    bottom: 20,
    right: 396
  },
  fab2: {
    background: "#0000ff",
    '&:hover': {
      background: "#0000aa",
    },
    color: "#ffffff",
    position: 'fixed',
    bottom: 20,
    right: 466
  },
  fab3: {
    background: "#00cc00",
    '&:hover': {
      background: "#00aa00",
    },
    color: "#ffffff",
    position: 'fixed',
    bottom: 20,
    right: 12
  },
  fab4: {
    background: "#0000ff",
    '&:hover': {
      background: "#0000aa",
    },
    color: "#ffffff",
    position: 'fixed',
    bottom: 20,
    right: 80
  },
}));

// App
export default function App() {
  const classes = useStyles();
  Global.institution = useInstitution();
  Global.user = useUser();
  Global.reservations = useReservations();
  Global.events = useEvents();
  const [loginDlg, setLoginDlg] = useState(false);
  const [reservationDlg, setReservationDlg] = useState(false);
  const [qrCodeReaderDlg, setQRCodeReaderDlg] = useState(false);
  const [reservationAdminDlg, setReservationAdminDlg] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [reservationsListDlg, setReservationsListDlg] = useState(false);
  const [confirmDlg, setConfirmDlg] = useState(false);
  const [confirmDeleteDlg, setConfirmDeleteDlg] = useState(false);
  const [eventFormDlg, setEventFormDlg] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [editing, setEditing] = useState(false);
  const [selectedEditEvent, setSelectedEditEvent] = useState(null);
  const [printDlg, setPrintDlg] = useState(false);
  document.body.style.backgroundColor = `${Global.institution.primary_color}10`;

  // Mostra login
  const onLogin = () => {
    setLoginDlg(true);
  }

  // Faz a reserva
  const onReservation = (event) => {
    setSelectedEvent(event);
    if (Global.user !== null && Global.user.type === 'Administrador') {
      setReservationAdminDlg(true);
    } else {
      setReservationDlg(true);
    }
  }

  // Diálogo da Lista de Reservas
  const onReservationsList = () => {
    setReservationsListDlg(true);
  }

  // Mostra diálogo de cancelamento
  const onCancel = (reservation) => {
    setReservation(reservation);
    setConfirmDlg(true);
  }

  // Cancela uma reserva
  const onCancelReservation = () => {
    const data = {
      id: reservation.id,
      status: 'Cancelada',
      token: reservation.token
    }
    Reservations.save(data).then((result) => {
      window.location.reload();
    });
  }

  // Mostra diálogo de edição evento
  const onEventEdit = (event) => {
    console.log(event);
    setSelectedEditEvent(event);
    setEventFormDlg(true);
  }

  // Incluir novo evento
  const onNewEventBtn = () => {
    setSelectedEditEvent(null);
    setEditing(false);
    setEventFormDlg(true);
  }

  // Salva o evento ou mostra janela de confirmação em caso de exclusão
  const onSaveEvent = (event) => {
    setSelectedEditEvent(event);
    if (event.deleted === 1) {
      setEventFormDlg(false);
      setConfirmDeleteDlg(true);
    } else {
      saveEvent(event);
    }
  }

  // Salva o evento
  const saveEvent = (event) => {
    Events.save(event).then((result) => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  // Modo edição
  const onEditingBtn = () => {
    setEditing(!editing);
  }

  // QrCode Reader
  const onQRCodeReader = () => {
    setQRCodeReaderDlg(true);
  }

  const isEventDay = () => {
    const event = Global.events.find((event) => Global.reservations[0].event_id === event.id);
    if (event!==undefined && event!==null) {
      const eventDate = Utils.dateToMysql(new Date(event.date_ref));
      const today = Utils.dateToMysql(new Date());
      return eventDate.substr(0,10) <= today.substr(0,10);
    } 
    return false;
  }

  return (
    <div>
      <main className={classes.content}>
        <MobileView>
          <UserControl onLogin={onLogin} onReservationsList={onReservationsList} onQRCodeReader={onQRCodeReader} />
        </MobileView>
        {isMobile && Global.reservations.length > 0 && Global.user !== null && Global.user.type === 'Visitante' && isEventDay() ?
          <QRCodeCard />
          :
          (Global.user === null || Global.user.type !== 'Controlador' ? <MainArea onLogin={onLogin} onReservation={onReservation} onCancel={onCancel} editing={editing} onEditing={onEventEdit} /> : <PresenceList />)
        }
      </main>
      <BrowserView>
        <DesktopBar onLogin={onLogin} onReservationsList={onReservationsList} onQRCodeReader={onQRCodeReader} />
      </BrowserView>
      <LoginDlg open={loginDlg} setOpen={setLoginDlg} />
      <EventFormDlg key={selectedEditEvent != null ? 'ev' + selectedEditEvent.id : 'ev0'} open={eventFormDlg} setOpen={setEventFormDlg} event={selectedEditEvent} onSaveEvent={onSaveEvent} />
      <ReservationDlg open={reservationDlg} setOpen={setReservationDlg} event={selectedEvent} />
      <QRCodeReaderDlg open={qrCodeReaderDlg} setOpen={setQRCodeReaderDlg} />
      <ReservationAdminDlg open={reservationAdminDlg} setOpen={setReservationAdminDlg} event={selectedEvent} />
      <ConfirmDlg key={selectedEditEvent != null ? selectedEditEvent.id : 0} open={confirmDeleteDlg} setOpen={setConfirmDeleteDlg} title='Confirmação de Exclusão' text='Tem certeza que deseja excluir este evento?' option1='Não' option2='Sim' onOption1={() => setConfirmDeleteDlg(false)} onOption2={() => saveEvent(selectedEditEvent)} />
      <ReservationsListDlg onPrint={() => setPrintDlg(true)} key={'rd' + Global.events.length} open={reservationsListDlg} setOpen={setReservationsListDlg} />
      <EventsPrintDlg open={printDlg} setOpen={setPrintDlg}/>
      <ConfirmDlg open={confirmDlg} setOpen={setConfirmDlg} title='Confirmação de Cancelamento' text='Tem certeza que deseja cancelar sua reserva?' option1='Não' option2='Sim' onOption1={() => setConfirmDlg(false)} onOption2={onCancelReservation} />
      {Global.user !== null && Global.user.type === 'Administrador' ?
        (isMobile ? <div>
          {editing ?
            <Fab aria-label="add" className={classes.fab4} onClick={onEditingBtn}>
              <EdiIcon />
            </Fab>
            :
            <Fab aria-label="add" className={classes.fab4} onClick={onEditingBtn}>
              <DelIcon />
            </Fab>}
          <Fab aria-label="add" className={classes.fab3} onClick={onNewEventBtn}>
            <AddIcon />
          </Fab>
        </div> : <div>
            {editing ?
              <Fab aria-label="add" className={classes.fab2} onClick={onEditingBtn}>
                <EdiIcon />
              </Fab>
              :
              <Fab aria-label="add" className={classes.fab2} onClick={onEditingBtn}>
                <DelIcon />
              </Fab>}
            <Fab aria-label="add" className={classes.fab} onClick={onNewEventBtn}>
              <AddIcon />
            </Fab>
          </div>)
        :
        null}
    </div>
  );
}