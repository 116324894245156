import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CircularProgress, TextField, Switch } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Utils from '../utils/utils';
import Globals from '../Globals';

const styles = makeStyles((theme) => ({
    seats: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    seatsField1: {
        marginRight: 8
    },
    seatsField2: {
        marginLeft: 0
    },
    datePlace: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    dateField: {
        marginRight: 16
    },
    placeField: {
        marginLeft: 16,
        width: '60%'
    },
    ativo: {
        marginTop: 0
    },
    image: {
        marginBottom: 16
    },
    eventImage: {
        width: '100%',
        borderRadius: 5,
        cursor: 'pointer'
    }
}));

// Diálogo de Edição de Eventos
export default function EventFormDlg(props) {
    const classes = styles();
    const { open, setOpen } = props;
    const [saving, setSaving] = useState(false);
    const [eventName, setEventName] = useState(props.event !== null ? props.event.name : '');
    const [eventType, setEventType] = useState(props.event !== null ? props.event.type : '');
    const [eventDate, setEventDate] = useState(props.event !== null ? new Date(props.event.date_ref) : new Date());
    const [eventPlace, setEventPlace] = useState(props.event !== null ? props.event.place : '');
    const [eventSeatsAvailable, setEventSeatsAvailable] = useState(props.event !== null ? props.event.seats_available : '');
    const [eventActive, setEventActive] = useState(props.event !== null ? props.event.active === 1 : true);
    const [eventImages, setEventImages] = useState(props.event !== null && props.event.photo !== '' ? [{ "data": Globals.apiURL + '/eventphoto/' + props.event.photo }] : []);

    // Salvando evento
    const onSaveBtn = () => {
        setSaving(true);
        props.onSaveEvent(getEvent(false));
    }

    // Exclui um evento
    const onDeleteBtn = () => {
        props.onSaveEvent(getEvent(true));
    }

    // Create the event to be saved
    const getEvent = (deleted) => {
        return {
            id: props.event !== null ? props.event.id : 0,
            photo: eventImages.length > 0 ? eventImages[0].data : '',
            name: eventName,
            type: eventType,
            date_ref: Utils.dateToMysql(new Date(eventDate)),
            place: eventPlace,
            seats_total: '0',
            seats_available: eventSeatsAvailable,
            active: eventActive ? 1 : 0,
            deleted: deleted ? 1 : 0
        }
    }

    // Exclui imagem
    const onDeleteImage = () => {
        setEventImages([]);
    }

    return (
        <div>
            <Dialog fullWidth={true} maxWidth='sm' onClose={() => setOpen(false)} open={open}>
                <DialogTitle onClose={() => setOpen(false)}>
                    <div className={classes.datePlace}>
                        {props.event === null || props.event === undefined ? 'NOVO EVENTO' : 'EDIÇÃO DE EVENTO'}
                        <FormControlLabel
                            className={classes.ativo}
                            labelPlacement='start'
                            control={
                                <Switch
                                    checked={eventActive}
                                    onChange={(event) => setEventActive(event.target.checked)}
                                    name="eventActive"
                                    color="primary"
                                />
                            }
                            label="Ativo"
                        />
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {eventImages.length === 0 ?
                        <DropzoneAreaBase
                            className={classes.image}
                            maxFileSize={100000000}
                            filesLimit={1}
                            acceptedFiles={['image/*']}
                            dropzoneText={"Arraste uma imagem ou clique aqui"}
                            onAdd={(files) => setEventImages(files)}
                            onDelete={(files) => setEventImages(files)}
                            fileObjects={eventImages}
                            showAlerts={false}
                            showPreviews={false}
                            showPreviewsInDropzone={true}
                            showFileNames={false}
                        /> :
                        <img src={eventImages[0].data} alt='Imagem' title='Clique para trocar de imagem' className={classes.eventImage} onClick={onDeleteImage} />}
                    <TextField
                        id="txtName"
                        size="small"
                        label="Título do Evento"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={eventName}
                        onChange={(event) => setEventName(event.target.value)}
                    />
                    <TextField
                        id="txtType"
                        size="small"
                        label="Tipo"
                        helperText="Exemplos: Culto, Reunião, Concerto, etc."
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={eventType}
                        onChange={(event) => setEventType(event.target.value)}
                    />
                    <div className={classes.datePlace}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.dateField} locale={ptLocale}>
                            <KeyboardDateTimePicker
                                margin="normal"
                                id="txtEventDate"
                                label="Data e Hora"
                                value={eventDate}
                                onChange={(date) => setEventDate(date)}
                                ampm={false}
                                disablePast={false}
                                invalidDateMessage=''
                                format='dd/MM/yyyy HH:mm'
                                KeyboardButtonProps={{
                                    'aria-label': 'mudar data',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <TextField
                            className={classes.placeField}
                            id="txtPlace"
                            size="small"
                            label="Local"
                            margin="normal"
                            variant="outlined"
                            value={eventPlace}
                            onChange={(event) => setEventPlace(event.target.value)}
                        />
                    </div>
                    <TextField
                        className={classes.seatsField2}
                        id="txtSeatsAvailable"
                        size="small"
                        label="Assentos Disponibilizados"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={eventSeatsAvailable}
                        onChange={(event) => setEventSeatsAvailable(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    {saving ?
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <CircularProgress style={{ color: Globals.institution.primary_color }} />
                        </div>
                        :
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            {props.event === null || props.event === undefined ? null : <Button autoFocus style={{ backgroundColor: '#cc0000', marginLeft: 8, marginRight: 8, borderRadius: '20px', marginBottom: '16px', paddingRight: '20px', paddingLeft: '20px', color: '#ffffff' }} onClick={onDeleteBtn}>Excluir</Button>}
                            <Button autoFocus style={{ backgroundColor: Globals.institution.primary_color, borderRadius: '20px', marginBottom: '16px', marginLeft: 8, marginRight: 8, paddingRight: '20px', paddingLeft: '20px', color: '#ffffff' }} onClick={onSaveBtn}>Salvar</Button>
                        </div>}
                </DialogActions>
            </Dialog>
        </div>
    )
}